import { AnalyticsBrowser } from "@segment/analytics-next";
import { createContext, useContext, useMemo } from "react";

const AnalyticsContext = createContext<AnalyticsBrowser>(undefined!);

type Props = {
  writeKey: string;
  children: React.ReactNode;
};

export const AnalyticsProvider = ({ children, writeKey }: Props) => {
  const analytics = useMemo(() => AnalyticsBrowser.load({ writeKey }), [writeKey]);

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = () => {
  const result = useContext(AnalyticsContext);

  if (process.env.NODE_ENV === "development") {
    return { track: () => console.log("track"), page: () => console.log("page") };
  }
  if (!result) {
    throw new Error("Context used outside of its Provider!");
  }
  return result;
};
